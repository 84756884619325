import { ValidationUtils } from "ferrum-plumbing";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { CrucibleService } from "../contractSync/service/crucibleService";
import {
  CRUCIBLE_CONTRACTS_V_0_1,
  PROVIDERS,
  STAKING_CONTRACTS_V_0_1,
} from "../contractSync/utils/contracts";
import { ContractHelper } from "./utils/contractHelper";

export const CrucibleClientActions = {
  CRUCIBLES_LOADED: "CRUCIBLES_LOADED",
  CRUCIBLE_LOADED: "CRUCIBLE_LOADED",
  USER_CRUCIBLE_LOADED: "USER_CRUCIBLE_LOADED",
  SELECT_CRUCIBLE: "SELECT_CRUCIBLE",
  PROCESSING_REQUEST: "PROCESSING_REQUEST",
  APP_ACTION_ERROR: "APP_ACTION_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  LOADED_CRUCIBLE_PRICE: "LOADED_CRUCIBLE_PRICE",
};

const Actions = CrucibleClientActions;

export class CrucibleClient {
  instance: CrucibleService;

  constructor() {
    this.instance = new CrucibleService(PROVIDERS, {
      stakingContracts: STAKING_CONTRACTS_V_0_1,
      contracts: CRUCIBLE_CONTRACTS_V_0_1,
    });
  }

  __name__() {
    return "CrucibleClient";
  }

  async getCrucible(crucibleCurrency: string, staking: string) {
    try {
      const [network, address] = ContractHelper.parseCurrency(crucibleCurrency);
      const crucible = await this.updateCrucible(network, address, staking);
      return crucible;
    } catch (e) {
      if ((e as Error).message.includes("call revert exception")) {
        return;
      }
      //
    } finally {
    }
  }

  async getUserCrucibleInfo(
    crucible: string,
    staking: string,
    address: string
  ) {
    try {
      const userCrucibleInfo = await this.instance.getUserCrucibleInfo(
        crucible,
        address,
        staking
      );
      if (!!userCrucibleInfo) {
      }
      return userCrucibleInfo;
    } catch (e) {
      console.log(e);
      if ((e as Error).message.includes("call revert exception")) {
        return;
      }
    } finally {
    }
  }

  async updateCrucible(
    network: string,
    contractAddress: string,
    staking?: string
  ) {
    const crucible = await this.instance.getCrucible(
      `${network.toUpperCase()}:${contractAddress}`,
      staking
    );
    console.log(crucible, " here");
    //  await this.api.api({
    // 	command: 'getCrucible',
    // 	data: {crucible: `${network.toUpperCase()}:${contractAddress}`},
    // 	params: [],
    // } as JsonRpcRequest);
    if (!!crucible) {
    }
    return crucible;
  }

  async deposit(
    currency: string,
    crucible: string,
    amount: string,
    address: string
  ) {
    try {
      const tx = await this.instance.depositPublicGetTransaction(
        currency,
        crucible,
        amount,
        address
      );
      return tx;
    } catch (e: any) {
      if (e.code && e.code === 4001) {
        return;
      }
    }
  }

  async depositAndStake(
    currency: string,
    crucible: string,
    stakeAddress: string,
    amount: string,
    isPublic: boolean
  ) {
    try {
      //
    } catch (e) {
      console.error("deposit", e);
      //@ts-ignore
      if (e.code && e.code === 4001) {
        return;
      }
    }
  }

  async stakeCrucible(
    currency: string,
    crucible: string,
    amount: string,
    stake: string,
    address: string
  ) {
    try {
      const [network] = ContractHelper.parseCurrency(currency);
      console.log({ network });
      const tx = await this.instance.StakeTransaction(
        crucible,
        amount,
        stake,
        address
      );
      console.log({ tx });
      return tx;
    } catch (e) {
      console.error("deposit", e);
      //@ts-ignore
      if (e.code && e.code === 4001) {
        return;
      }
    }
  }

  async withdraw(
    currency: string,
    crucible: string,
    amount: string,
    from: string
  ) {
    try {
      const tx = await this.instance.WithdrawTransaction(
        crucible,
        amount,
        from
      );
      return tx;
    } catch (e) {
      console.error("deposit", e);
      //@ts-ignore
      if (e.code && e.code === 4001) {
        return;
      }
    }
  }

  async stakeFor(
    dispatch: Dispatch<AnyAction>,
    stakeId: string,
    currency: string,
    amount: string,
    address: string
  ) {
    try {
      const [network] = ContractHelper.parseCurrency(currency);
      const tx = await this.instance.StakeTransaction(
        currency,
        amount,
        (await this.contract(network)).staking,
        address
      );
      console.log(tx, "reqreqree");
      if (!!tx) {
        const res = await this.instance.sendTransactionAsync([tx], {});
        if (!!res) {
          return res;
        }

        return res;
      }
    } catch (e) {
      console.log(e, (e as Error).message);
      //@ts-ignore
      if (e.code && e.code === 4001) {
        return;
      }
    }
  }

  async unstake(
    crucible: string,
    amount: string,
    staking: string,
    address: string
  ) {
    try {
      const tx = await this.instance.UnStakeTransaction(
        crucible,
        amount,
        staking,
        address
      );
      return tx;
    } catch (e) {
      console.log(e, (e as Error).message);
      //@ts-ignore
      if (e.code && e.code === 4001) {
        return;
      }
    }
  }

  async withdrawRewards(
    crucible: string,
    address: string,
    amount: string,
    staking: string
  ) {
    try {
      const tx = await this.instance.withdrawRewards(
        address,
        crucible,
        amount,
        staking
      );
      return tx;
    } catch (e) {
      console.log(e, (e as Error).message);
      //@ts-ignore
      if (e.code && e.code === 4001) {
        return;
      }
    }
  }

  async contract(network: string) {
    const cc = CRUCIBLE_CONTRACTS_V_0_1[network];
    ValidationUtils.isTrue(!!cc, `Network "${network}" not supported`);
    return cc;
  }
}
