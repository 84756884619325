import React from "react";
import Layout from "./layouts";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import BuyCard from "./page/crucible/BuyCard";
import MintCard from "./page/crucible/mint/MintCard";
import Stake from "./page/crucible/stake/Stake";
import UnStake from "./page/crucible/un-stake/UnStake";
import UnWrap from "./page/crucible/un-wrap/UnWrap";
import CrucibleCard from "./page/crucible/CrucibleCard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <CrucibleCard />,
        children: [
          {
            index: true,
            element: <MintCard />,
          },
          {
            path: "buy",
            element: <BuyCard />,
          },
          {
            path: "mint",
            element: <MintCard />,
          },
          {
            path: "stake",
            element: <Stake />,
          },
          {
            path: "un-stake",
            element: <UnStake />,
          },
          {
            path: "un-wrap",
            element: <UnWrap />,
          },
          {
            path: "*",
            element: <Navigate to="/buy" replace />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
