import { atom } from "recoil";

export interface NetworkAndToken {
  networkName: string;
  networkId: number;
  crucibleName: string;
  networkImageUrl: string;
  networkRpcUrl: string;
  tokenName: string;
  tokenAddress: string;
  tokenImageUrl: string;
  stakingAddress: string;
}

export interface UnStakeState {
  isUnStaking: boolean;
  errorMessage: string;
  successMessage : string;
  sourceNetworkAndToken?: NetworkAndToken;
  destinationNetworkAndToken?: NetworkAndToken;
  amount: string;
  unStakeLimit: string;
  feeOnUnStake: string;
}

export const INITIAL_UN_STAKING_STATE: UnStakeState = {
  isUnStaking: false,
  errorMessage: "",
  successMessage: "",
  sourceNetworkAndToken: undefined,
  destinationNetworkAndToken: undefined,
  amount: "",
  unStakeLimit: "0.0",
  feeOnUnStake: "0.0"
};
export const unStakingState = atom({
  key: "unStakingState",
  default: INITIAL_UN_STAKING_STATE,
});
