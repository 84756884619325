import { useEffect, useState } from "react";
import { Button, Variant } from "../../../components/ui";
import { Gif, ReactIcons } from "../../../assets";
import ConnectWalletDialog from "../../../components/ConnectWalletDialog";
import { Tooltip } from "react-tooltip";
import { useRecoilState } from "recoil";
import {
  useWalletConnector,
  useFoundrySendTransaction,
  useSwitchNetwork,
} from "foundry";
import { StakeState, stakingState } from "../../../recoil/stake";
import StakeDetailsSelectorCard from "./StakeDetailsSelectorCard";
import { CrucibleClient } from "../../../utils/contractSync/CrucibleClient";
import Web3 from "web3";
import { Web3Helper } from "../../../utils/Web3Helper";
import Big from "big.js";
import ApproveRouterJson from "../../../utils/abi/ApproveRouterAbi.json";
import { AbiItem } from "web3-utils";
import {
  ARBITRUM_CFRM,
  ARBITRUM_FRM,
  BSC_CFRM,
} from "../../../utils/constants";
import { MessageState, messagingState } from "../../../recoil/app";

const Stake = () => {
  const [stakeState, setStakeState] = useRecoilState<StakeState>(stakingState);

  const {
    isStaking,
    sourceNetworkAndToken,
    destinationNetworkAndToken,
    errorMessage,
    amount,
    isApproving,
    allowance,
    feeOnStake
  } = stakeState;

  const [stakedBalance,setStakedbalance]=useState<string>("0.0")
  const [messageState,setMessageState] = useRecoilState<MessageState>(messagingState);
  const [showConnectWalletDialog, setShowConnectWalletDialog] =
    useState<boolean>(false);
  const { isConnected, walletAddress, currentNetworkChainId } =
    useWalletConnector();
  const { switchWeb3Network } = useSwitchNetwork();

  const {
    isPending,
    hash,
    isConfirmed,
    isConfirming,
    reciept,
    status,
    error,
    reset,
    sendWeb3Transaction,
  } = useFoundrySendTransaction();

  const getCrucibleInfoAndUserInfo = async () => {
    if (destinationNetworkAndToken && sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const info = await client.getCrucible(
        `${sourceNetworkAndToken.crucibleName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        sourceNetworkAndToken.stakingAddress
      );
      const userInfo = await client.getUserCrucibleInfo(
        `${sourceNetworkAndToken.crucibleName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        sourceNetworkAndToken.stakingAddress,
        walletAddress
      );
      console.log("userInfo", userInfo);
      console.log("crucibleInfo", info);
      console.log("stakeOf ", userInfo?.data.stakes[0].stakeOf);
      setStakedbalance(userInfo?.data?.stakes[0]?.stakeOf);
    }
  };

  useEffect(() => {
    if (destinationNetworkAndToken?.tokenAddress && walletAddress) {
      getCrucibleInfoAndUserInfo();
    }
  }, [destinationNetworkAndToken?.tokenAddress, walletAddress]);

  useEffect(()=>{
    setMessageState({
      errorMessage:"",
      successMessage:""
    })
  },[])

  useEffect(() => {
    if (isConnected) {
      setShowConnectWalletDialog(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected && currentNetworkChainId && !sourceNetworkAndToken) {
      if (currentNetworkChainId === ARBITRUM_CFRM.networkId) {
        setStakeState({
          ...stakeState,
          sourceNetworkAndToken: ARBITRUM_CFRM,
          destinationNetworkAndToken: ARBITRUM_CFRM,
          feeOnStake: "0",
        });
      } else if (currentNetworkChainId === BSC_CFRM.networkId) {
        setStakeState({
          ...stakeState,
          sourceNetworkAndToken: BSC_CFRM,
          destinationNetworkAndToken: BSC_CFRM,
          feeOnStake: "0.3",
        });
      } else {
        setStakeState({
          ...stakeState,
          sourceNetworkAndToken: ARBITRUM_CFRM,
          destinationNetworkAndToken: ARBITRUM_CFRM,
          feeOnStake: "0",
        });
      }
    }
  }, [isConnected, currentNetworkChainId, sourceNetworkAndToken]);

  useEffect(() => {
    if (
      sourceNetworkAndToken?.networkId &&
      sourceNetworkAndToken?.tokenAddress &&
      walletAddress
    ) {
      getCurrentApprovedAmount(walletAddress, false, false);
    }
  }, [sourceNetworkAndToken?.tokenAddress, sourceNetworkAndToken?.networkId]);

  useEffect(() => {
    if (isStaking && hash && status === "success") {
      reset();
      getCrucibleInfoAndUserInfo();
      getCurrentApprovedAmount(walletAddress, false, true);
    } else if (isStaking && error) {
      setStakeState({
        ...stakeState,
        isApproving: false,
        isStaking: false,
        errorMessage: "An error occured while staking",
      });
      setMessageState({
        errorMessage: "An error occured while staking",
        successMessage: "",
      })
      reset();
    }
  }, [isStaking, hash, status, error]);

  useEffect(() => {
    if (isApproving && hash && status === "success") {
      reset();
      getCurrentApprovedAmount(walletAddress, true, false);
    } else if (isApproving && error) {
      setStakeState({
        ...stakeState,
        isStaking: false,
        isApproving: false,
        errorMessage: "An error occured while approving",
        successMessage: "",
        amount: "",
      });
      setMessageState({
        errorMessage: "An error occured while approving",
        successMessage: "",
      })
      reset();
    }
  }, [isApproving, hash, status, error]);

  const getCurrentApprovedAmount = async (
    walletAddress: string,
    isAfterApproval: boolean,
    isAfterStake: boolean
  ) => {
    const web3 = new Web3(sourceNetworkAndToken?.networkRpcUrl);
    const web3Helper = new Web3Helper(web3);
    const response = await web3Helper.getStandardContractAllocation(
      String(sourceNetworkAndToken?.tokenAddress),
      walletAddress,
      sourceNetworkAndToken?.router!
    );
    // console.log("router", sourceNetworkAndToken?.router);
    // console.log("response.allowance", response.allowance);
    if (isAfterApproval) {
      setStakeState({
        ...stakeState,
        allowance: response.allowance,
        isApproving: false,
        isStaking: true,
        successMessage: "",
        errorMessage: "",
      });
      setMessageState({
        errorMessage: "",
        successMessage: "",
      })
      handleStakingAction(isAfterApproval);
    } else if (isAfterStake) {
      setStakeState({
        ...stakeState,
        amount: "",
        finalStakeAmount:"",
        errorMessage: "",
        successMessage: "Stake successful",
        allowance: response.allowance,
        isApproving: false,
        isStaking: false,
      });
      setMessageState({
        errorMessage: "",
        successMessage: "Stake successful",
      })
    } else {
      setStakeState({
        ...stakeState,
        errorMessage: "",
        successMessage: "",
        allowance: response.allowance,
        isApproving: false,
        isStaking: false,
      });
      setMessageState({
        errorMessage: "",
        successMessage: "",
      })
    }
  };

  const approveContractAllocation = async (
    walletAddress: string,
    web3Sdk: any,
    amount: string
  ) => {
    try {
      setStakeState({
        ...stakeState,
        isApproving: true,
        isStaking: false,
        successMessage: "",
        errorMessage: "",
      });
      setMessageState({
        errorMessage: "",
        successMessage: "",
      })
      const contractInstance = new web3Sdk.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        sourceNetworkAndToken?.tokenAddress
      );
      const decimals = await contractInstance.methods.decimals().call();
      const payload = {
        from: walletAddress,
        to: sourceNetworkAndToken?.tokenAddress,
        data: contractInstance.methods
          .approve(
            sourceNetworkAndToken?.router,
            new Big(amount)
              .times(10 ** Number(decimals))
              .toFixed()
              .toString()
          )
          .encodeABI(),
      };
      sendWeb3Transaction(payload);
    } catch (error) {
      // console.log("approving", error);
      setStakeState({
        ...stakeState,
        isApproving: false,
      });
      setMessageState({
        successMessage: "",
        errorMessage: "An error occured"
      })
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  };

  const handleApproveAction = () => {
    if (
      sourceNetworkAndToken?.networkId &&
      sourceNetworkAndToken?.tokenAddress &&
      amount
    ) {
      console.log("handleApproveAction");
      approveContractAllocation(
        walletAddress,
        new Web3(sourceNetworkAndToken?.networkRpcUrl),
        amount
      );
    }
  };
  console.log("allowance", allowance);

  const handleStakingAction = async (isAfterApproval: boolean) => {
    if (!isAfterApproval) {
      setStakeState({
        ...stakeState,
        isStaking: true,
        errorMessage: "",
        successMessage: "",
      });
      setMessageState({
        errorMessage: "",
        successMessage: "",
      })
    }
    if (destinationNetworkAndToken && sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const tx = await client.stakeCrucible(
        `${sourceNetworkAndToken.networkName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        `${destinationNetworkAndToken.networkName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        amount,
        destinationNetworkAndToken.stakingAddress,
        walletAddress
      );
      console.log("tx", tx);
      if (tx) {
        const payload = {
          from: tx.from,
          to: tx.contract,
          data: tx.data,
          value: "",
        };
        sendWeb3Transaction(payload);
      }
    }
  };

  const getButtonVariant = (): Variant => {
    return isStaking || isApproving ? "tertiary" : "primary";
  };

  const isButtonDisabled = () => {
    return isStaking || isApproving;
  };

  const getButtonPostfix = () => {
    if (false) {
      return <ReactIcons.GoLinkExternal />;
    }
  };

  const getButtonPrefix = () => {
    if (isStaking || isApproving) {
      return <img src={Gif.Loader} className="h-4 w-4" />;
    }
  };

  const getButtonTitle = () => {
    if (!isConnected) {
      return "Connect Wallet";
    }

    if (isConnected && !sourceNetworkAndToken) {
      return "Select Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      return "Switch Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(amount) || Number(amount) <= 0)
    ) {
      return "Enter Amount";
    }

    if (isStaking) {
      return "Staking";
    }

    if (isApproving) {
      return "Approving";
    }

    if (
      Number.isNaN(allowance) ||
      Number(allowance) <= 0 ||
      Number(allowance) < Number(amount)
    ) {
      return "Stake";
    }

    return "Stake";
  };

  const handleButtonClick = () => {
    if (!isConnected) {
      setShowConnectWalletDialog(true);
      return;
    }

    if (isConnected && !sourceNetworkAndToken) {
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      switchWeb3Network(sourceNetworkAndToken.networkId.toString());
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(amount) || Number(amount) <= 0)
    ) {
      return;
    }

    if (
      Number.isNaN(allowance) ||
      Number(allowance) <= 0 ||
      Number(allowance) < Number(amount)
    ) {
      console.log("Approve");
      handleApproveAction();
      return;
    }
    console.log("staking");
    handleStakingAction(false);
  };

  return (
    <>
      <StakeDetailsSelectorCard
        title="You’ll Deposit"
        side="source"
        balanceUpdated={status === "success"}
      />

      <StakeDetailsSelectorCard
        title="You’ll Stake"
        side="destination"
        balanceUpdated={status === "success"}
      />

      {walletAddress && (
        <div className="flex justify-between  items-center text-white">
          <span className="ml-2 py-1 text-[10px] flex">
            Fee on Stake {feeOnStake}%
          </span>
          <span className="mr-2 py-1 text-[10px] flex">
            Staked Balance : {stakedBalance ? Number(stakedBalance).toFixed(6) : Number("0.0").toFixed(6)}
          </span>
        </div>
      )}

      <Button
        variant={`${getButtonVariant()}`}
        title={`${getButtonTitle()}`}
        className="mt-4 w-full text-xl"
        prefix={getButtonPrefix()}
        postfix={getButtonPostfix()}
        disabled={isButtonDisabled()}
        onClick={handleButtonClick}
      />

      <ConnectWalletDialog
        show={showConnectWalletDialog}
        onHide={() => setShowConnectWalletDialog(false)}
      />
    </>
  );
};

export default Stake;
