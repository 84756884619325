import { useEffect, useState } from "react";
import { Button, Variant } from "../../../components/ui";
import { Gif, ReactIcons } from "../../../assets";
import ConnectWalletDialog from "../../../components/ConnectWalletDialog";
import { Tooltip } from "react-tooltip";
import { useRecoilState } from "recoil";

import {
  useWalletConnector,
  useFoundrySendTransaction,
  useSwitchNetwork,
} from "foundry";
import { useTokenBalance } from "../../../hooks";
import { UnStakeState, unStakingState } from "../../../recoil/unStake";
import UnStakeDetailsSelectorCard from "./UnStakeDetailsSelectorCard";
import { CrucibleClient } from "../../../utils/contractSync/CrucibleClient";
import { ARBITRUM_CFRM, BSC_CFRM } from "../../../utils/constants";
import { MessageState, messagingState } from "../../../recoil/app";

const UnStake = () => {
  const [unStakeState, setUnStakeState] =
    useRecoilState<UnStakeState>(unStakingState);
  const [messageState, setMessageState] =
    useRecoilState<MessageState>(messagingState);

  const {
    isUnStaking,
    sourceNetworkAndToken,
    destinationNetworkAndToken,
    amount,
    unStakeLimit,
    feeOnUnStake
  } = unStakeState;

  const [showAddressField, setShowAddressField] = useState<boolean>(false);
  const [showConnectWalletDialog, setShowConnectWalletDialog] =
    useState<boolean>(false);
  const { isConnected, walletAddress, currentNetworkChainId } =
    useWalletConnector();
  const { switchWeb3Network } = useSwitchNetwork();

  const { hash, status, error, reset, sendWeb3Transaction } =
    useFoundrySendTransaction();

  const getCrucibleInfoAndUserInfo = async () => {
    if (destinationNetworkAndToken && sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const info = await client.getCrucible(
        `${sourceNetworkAndToken.crucibleName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        sourceNetworkAndToken.stakingAddress
      );
      const userInfo = await client.getUserCrucibleInfo(
        `${sourceNetworkAndToken.crucibleName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        sourceNetworkAndToken.stakingAddress,
        walletAddress
      );
      console.log("userInfo", userInfo);
      console.log("crucibleInfo", info);
      console.log("stakeOf ", userInfo?.data.stakes[0].stakeOf);
      setUnStakeState({
        ...unStakeState,
        amount:"",
        unStakeLimit: userInfo?.data.stakes[0].stakeOf,
        isUnStaking:false,
      });
    }
  };

  useEffect(() => {
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });
  }, []);

  useEffect(() => {
    if (destinationNetworkAndToken?.tokenAddress && walletAddress) {
      getCrucibleInfoAndUserInfo();
    }
  }, [destinationNetworkAndToken?.tokenAddress, walletAddress]);

  useEffect(() => {
    if (isConnected) {
      setShowConnectWalletDialog(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (isConnected && currentNetworkChainId && !sourceNetworkAndToken) {
      if (currentNetworkChainId === ARBITRUM_CFRM.networkId) {
        setUnStakeState({
          ...unStakeState,
          sourceNetworkAndToken: ARBITRUM_CFRM,
          destinationNetworkAndToken: ARBITRUM_CFRM,
          feeOnUnStake:"2"
        });
      } else if (currentNetworkChainId === BSC_CFRM.networkId) {
        setUnStakeState({
          ...unStakeState,
          sourceNetworkAndToken: BSC_CFRM,
          destinationNetworkAndToken: BSC_CFRM,
          feeOnUnStake:"0.0"
        });
      } else {
        setUnStakeState({
          ...unStakeState,
          sourceNetworkAndToken: ARBITRUM_CFRM,
          destinationNetworkAndToken: ARBITRUM_CFRM,
          feeOnUnStake:"2"
        });
      }
    }
  }, [isConnected, currentNetworkChainId, sourceNetworkAndToken]);

  useEffect(() => {
    if (isUnStaking && hash && status === "success") {
      setMessageState({
        errorMessage: "",
        successMessage: "Unstaking successful",
      });
      getCrucibleInfoAndUserInfo();
      reset();
    } else if (isUnStaking && error) {
      setUnStakeState({
        ...unStakeState,
        isUnStaking: false,
      });
      setMessageState({
        errorMessage: "An error occured while un staking",
        successMessage: "",
      });
      reset();
    }
  }, [isUnStaking, hash, status, error]);

  const handleUnStakeAction = async () => {
    console.log(amount)
    setUnStakeState({
      ...unStakeState,
      isUnStaking: true,
    });
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });

    if (destinationNetworkAndToken) {
      const client = new CrucibleClient();
      const tx = await client.unstake(
        `${destinationNetworkAndToken.crucibleName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        amount,
        destinationNetworkAndToken.stakingAddress,
        walletAddress
      );
      if (tx) {
        const payload = {
          from: tx.from,
          to: tx.contract,
          data: tx.data,
        };
        sendWeb3Transaction(payload);
      }
    }
  };

  const getButtonVariant = (): Variant => {
    return isUnStaking ? "tertiary" : "primary";
  };

  const isButtonDisabled = () => {
    return isUnStaking;
  };

  const getButtonPostfix = () => {
    if (false) {
      return <ReactIcons.GoLinkExternal />;
    }
  };

  const getButtonPrefix = () => {
    if (isUnStaking) {
      return <img src={Gif.Loader} className="h-4 w-4" />;
    }
  };

  const getButtonTitle = () => {
    if (!isConnected) {
      return "Connect Wallet";
    }

    if (isConnected && !sourceNetworkAndToken) {
      return "Select Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      return "Switch Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(amount) || Number(amount) <= 0)
    ) {
      return "Enter Amount";
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      Number(amount) > Number(unStakeLimit)
    ) {
      return "Not Enough Balance To UnStake";
    }

    if (isUnStaking) {
      return "UnStaking";
    }

    return "UnStake";
  };

  const handleButtonClick = () => {
    if (!isConnected) {
      setShowConnectWalletDialog(true);
      return;
    }

    if (isConnected && !sourceNetworkAndToken) {
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      switchWeb3Network(sourceNetworkAndToken.networkId.toString());
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(amount) ||
        Number(amount) <= 0 ||
        Number(amount) > Number(unStakeLimit))
    ) {
      return;
    }
    handleUnStakeAction();
  };

  return (
    <>
      <UnStakeDetailsSelectorCard
        title="You’ll Deposit"
        side="source"
        balanceUpdated={status === "success"}
      />

      <UnStakeDetailsSelectorCard
        title="You’ll Unstake"
        side="destination"
        balanceUpdated={status === "success"}
      />

      {walletAddress && (
        <div className="flex items-center text-white">
          <span className="ml-2 py-1 text-[10px] flex">
            Fee on UnStake {feeOnUnStake}%
          </span>
        </div>
      )}

      <Button
        variant={`${getButtonVariant()}`}
        title={`${getButtonTitle()}`}
        className="mt-4 w-full text-xl"
        prefix={getButtonPrefix()}
        postfix={getButtonPostfix()}
        disabled={isButtonDisabled()}
        onClick={handleButtonClick}
      />

      <ConnectWalletDialog
        show={showConnectWalletDialog}
        onHide={() => setShowConnectWalletDialog(false)}
      />
    </>
  );
};

export default UnStake;
