// react icons imports
import { RiArrowDropDownLine } from "react-icons/ri";
import { VscSettings } from "react-icons/vsc";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaArrowDown, FaArrowRight, FaExternalLinkAlt } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { PiWarningCircle } from "react-icons/pi";
import { FaXTwitter } from "react-icons/fa6";
import { IoOpenOutline } from "react-icons/io5";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { GoLinkExternal, GoCopy } from "react-icons/go";
import { LiaFilterSolid } from "react-icons/lia";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { MdError } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdOutlineCurrencyExchange } from "react-icons/md";


// custom icons imports
import MsLogo from "./icons/ms-icon.svg";
import DetailIcon from "./icons/detail-icon.svg";
import SwapIcon from "./icons/swap-icon.svg";
import WalletIcon from "./icons/wallet-icon.svg";
import ListIcon from "./icons/list-icon.svg";
import CrossIcon from "../assets/icons/close-icon.svg";
import SearchIcon from "../assets/icons/search-icon.svg";
import MetamaskIcon from "../assets/icons/metamask-icon.svg";
import WalletConnect from "../assets/icons/wallet-connect-icon.svg";
import CoinBase from "../assets/icons/coinbase-icon.svg";
import GateIcon from "../assets/icons/gate-wallet-icon.svg";
import BraveIcon from "../assets/icons/brave-wallet-icon.svg";
import TrustWalletIcon from "../assets/icons/trust-wallet-icon.svg";
import KeplrIcon from "../assets/icons/keplr-icon.svg";
import BitgetIcon from "../assets/icons/bitget-icon.svg";
import FaRegStar from "../assets/icons/star-icon.svg";
import AddCircleOutline from "../assets/icons/add-circle-outline-icon.svg";
import HexagonWarningIcon from "../assets/icons/warning-icon.svg";
import PathIcon from "../assets/icons/path-icon.svg";
import SettingIcon from "../assets/icons/setting-icon.svg";
import MenuIcon from "../assets/icons/menu-icon.svg";
import AboutIcon from "../assets/icons/about-icon.svg";
import TimerIcon from "../assets/icons/timer-icon.svg";
import GasIcon from "../assets/icons/gas-icon.svg";
import BscIcon from "../assets/icons/bsc-icon.svg";
import MdArrowForward from "../assets/icons/arrow-forward-icon.svg";
import ExplorerIcon from "../assets/icons/explorer-icon.svg";
import FerrumBanner from "../assets/images/SVG/Ferrum-banner.svg";
import ReceiverWalletIcon from "../assets/icons/receiver-wallet-icon.svg";
import PalindromeIcon from "../assets/icons/palindrome-icon.svg";
import CircularTickIcon from "../assets/icons/circle-tick-icon.svg";
import PasteIcon from "../assets/icons/paste-icon.svg";
import ErrorIcon from "../assets/icons/error-icon.svg";
import ErrorIconInfo from "../assets/icons/error-icon-info.svg";
import ErrorIconWarning from "../assets/icons/error-icon-warning.svg";
import ErrorIconRejection from "../assets/icons/error-icon-rejection.svg";
import CrucibleLogo from "../assets/icons/crucible-icon.svg"
import BridgeIcon from "../assets/icons/bridge-icon.svg"
import {ReactComponent as BuyIconDark} from "../assets/icons/buy-icon-dark.svg"
import {ReactComponent as BuyIconLight} from "../assets/icons/buy-icon-light.svg"
import {ReactComponent as MintIconDark} from "../assets/icons/mint-icon-dark.svg"
import {ReactComponent as MintIconLight} from "../assets/icons/mint-icon-light.svg"
import {ReactComponent as StakeIconDark} from "../assets/icons/stake-icon-dark.svg"
import {ReactComponent as StakeIconLight} from "../assets/icons/stake-icon-light.svg"
import {ReactComponent as RewardsIconDark} from "../assets/icons/rewards-icon-dark.svg"
import {ReactComponent as RewardsIconLight} from "../assets/icons/rewards-icon-light.svg"
import {ReactComponent as UnstakeIconDark} from "../assets/icons/unstake-icon-dark.svg"
import {ReactComponent as UnstakeIconLight} from "../assets/icons/unstake-icon-light.svg"
import {ReactComponent as UnwrapIconDark} from "../assets/icons/unwrap-icon-dark.svg"
import {ReactComponent as UnwrapIconLight} from "../assets/icons/unwrap-icon-light.svg"
import DropDownArrow from "../assets/icons/dropdown-arrow.svg"
import {ReactComponent as DropDownArrowDark} from "../assets/icons/dropdown-arrow-dark.svg"
import { ReactComponent as SuccessMsgIcon } from "../assets/icons/success-mg-icon.svg";


//Gif imports
import Loader from "../assets/gif/animation-loading.gif";

// images SVGs imports
import BackgroundGradientSVG from "./images/SVG/ms-background-gradient.svg";
import FiberEngineBannerLogo from "./images/SVG/fiber-engine-logo.svg";
import FiberEngineLogo from "./images/SVG/fiber-engine.svg";

// images SVGs imports
import BackgroundGradientPNG from "./images/PNG/ms-background-gradient.png";

// components imports
import { ReactComponent as WalletIconSVG } from "./icons/wallet-icon.svg";

// animation imports
import BackgroundGradient from "./animation/gradient-animation.json";

export const ReactIcons = {
  RiArrowDropDownLine,
  VscSettings,
  IoInformationCircleOutline,
  FaArrowDown,
  PiWarningCircle,
  FaArrowRight,
  FaXTwitter,
  IoOpenOutline,
  IoArrowBack,
  RxQuestionMarkCircled,
  GoLinkExternal,
  LiaFilterSolid,
  IoIosArrowBack,
  IoIosArrowForward,
  MdError,
  IoMdClose,
  GoCopy,
  IoIosCheckmarkCircle,
  FaExternalLinkAlt,
  MdOutlineCurrencyExchange
};

export const Icons = {
  MsLogo,
  SwapIcon,
  WalletIcon,
  ListIcon,
  CrossIcon,
  MetamaskIcon,
  WalletConnect,
  CoinBase,
  GateIcon,
  BraveIcon,
  TrustWalletIcon,
  KeplrIcon,
  BitgetIcon,
  SearchIcon,
  FaRegStar,
  AddCircleOutline,
  HexagonWarningIcon,
  PathIcon,
  SettingIcon,
  MenuIcon,
  AboutIcon,
  GasIcon,
  TimerIcon,
  BscIcon,
  MdArrowForward,
  ExplorerIcon,
  ReceiverWalletIcon,
  DetailIcon,
  PasteIcon,
  PalindromeIcon,
  CircularTickIcon,
  ErrorIcon,
  ErrorIconInfo,
  ErrorIconWarning,
  ErrorIconRejection,
  CrucibleLogo,
  BuyIconDark,
  BuyIconLight,
  MintIconDark,
  MintIconLight,
  StakeIconDark,
  StakeIconLight,
  RewardsIconDark,
  RewardsIconLight,
  UnstakeIconDark,
  UnstakeIconLight,
  UnwrapIconDark,
  UnwrapIconLight,
  DropDownArrow,
  DropDownArrowDark,
  BridgeIcon,
  SuccessMsgIcon
};

export const Gif = {
  Loader,
};

export const Images = {
  BackgroundGradientSVG,
  BackgroundGradientPNG,
  FiberEngineBannerLogo,
  FiberEngineLogo,
  FerrumBanner,
};

export const Animations = {
  BackgroundGradient,
};



export const Components = { WalletIconSVG };
