import { atom } from "recoil";

export interface NetworkAndToken {
  crucibleName: string;
  networkName: string;
  networkId: number;
  networkImageUrl: string;
  networkRpcUrl: string;
  tokenName: string;
  tokenAddress: string;
  tokenImageUrl: string;
  stakingAddress: string;
  router: string;
  factory: string;
}

export interface StakeState {
  allowance: string;
  isApproving: boolean;
  isStaking: boolean;
  errorMessage: string;
  successMessage: string;
  sourceNetworkAndToken?: NetworkAndToken;
  destinationNetworkAndToken?: NetworkAndToken;
  amount: string;
  feeOnStake : string;
  finalStakeAmount: string;
  stakedSoFar: string;
}

export const INITIAL_STAKING_STATE: StakeState = {
  allowance: "",
  isApproving: false,
  isStaking: false,
  errorMessage: "",
  successMessage: "",
  sourceNetworkAndToken: undefined,
  destinationNetworkAndToken: undefined,
  amount: "",
  feeOnStake: "0.0",
  finalStakeAmount :"0.0",
  stakedSoFar:"0.0"
};
export const stakingState = atom({
  key: "stakingState",
  default: INITIAL_STAKING_STATE,
});
