import axios from "axios";
import { getEncryptedKey } from "../utils/helper";

const GATEWAY_BACKEND_URL = process.env.REACT_APP_GATEWAY_BACKEND_URL;

export async function getAccessTokenForApplicationUser() {
  const key = getEncryptedKey();
  if (key) {
    const url = `${GATEWAY_BACKEND_URL}/api/v1/application-user/token`;
    return axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        apikey: key,
      },
    });
  }
}

export function connectToWalletAddress(values: any, token: string) {
  const url = `${GATEWAY_BACKEND_URL}/api/v1/application-user/addresses/connect/to/address?isFromOrganizationAdminPath=false`;
  return axios.post(url, values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function refreshToken(oldToken: string) {
  return axios.put(
    `${GATEWAY_BACKEND_URL}/api/v1/token/refresh`,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${oldToken}`,
      },
    },
  );
}
