import { useRecoilState } from "recoil";
import { Icons, ReactIcons } from "../../assets";
import { Button, Card } from "../../components/ui";
import { MessageState, messagingState } from "../../recoil/app";
import { useEffect } from "react";

interface BuyCardIprops {}
const BuyCard = ({}: BuyCardIprops) => {
  const [messageState, setMessageState] =
    useRecoilState<MessageState>(messagingState);

  useEffect(() => {
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });
  }, []);

  return (
    <>
      <Card variant="secondary" className="my-4">
        <div className="relative">
          <div className="px-4 py-5">
            <div className="row-start-1 pt-2 text-md flex items-center justify-center">
              <img
                className="mr-2"
                src={
                  "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png"
                }
                alt="swap Icon"
                width={25}
              />
              Arbitrum
            </div>
          </div>
          <div className="grid grid-flow-row gap-3 justify-center items-center px-20 py-1 md:pb-1 sm:flex sm:flex-row sm:justify-between">
            <Button
              variant={"secondary"}
              prefix={
                <img
                  className="cursor-pointer"
                  src={
                    "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454"
                  }
                  alt="frm icon"
                  width={25}
                />
              }
              title="BUY FRM"
              className="cursor-pointer !text-primary text-sm w-44"
              onClick={() => {
                window.open(
                  "https://swap.multiswap.network/swap?fromChainId=42161&fromToken=0xff970a61a04b1ca14834a43f5de4533ebddb5cc8&sourceSlippage=2&toChainId=42161&toToken=0x9f6abbf0ba6b5bfa27f4deb6597cc6ec20573fda&destinationSlippage=2",
                  "_blank"
                );
              }}
              postfix={
                <ReactIcons.FaExternalLinkAlt
                  className="ml-1 cursor-pointer"
                  size={10}
                />
              }
            />
            <Button
              variant={"secondary"}
              prefix={
                <img
                  src={
                    "https://ferrum.network/nwp/assets/Ferrum-Network/icon-network-cfrm.svg"
                  }
                  alt="cFRM Icon"
                  className="cursor-pointer"
                  width={25}
                />
              }
              title="BUY cFRM"
              className="cursor-pointer text-sm w-44 border-primaryLight"
              onClick={() => {
                window.open(
                  "https://www.sushi.com/swap?inputCurrency=0x9f6AbbF0Ba6B5bfa27f4deb6597CC6Ec20573FDA&outputCurrency=0xe685d3CC0be48BD59082eDe30C3b64CbFc0326e2&chainId=42161&token0=0x9f6AbbF0Ba6B5bfa27f4deb6597CC6Ec20573FDA&token1=0xe685d3CC0be48BD59082eDe30C3b64CbFc0326e2",
                  "_blank"
                );
              }}
              postfix={
                <ReactIcons.FaExternalLinkAlt
                  className="ml-1 cursor-pointer"
                  size={10}
                />
              }
            />
          </div>
          <div className="grid grid-flow-row justify-center gap-2 pt-3 pb-3 pl-16 pr-16  text-center sm:flex sm:justify-around sm:pt-5 sm:pb-5 sm:pl-16 sm:pr-16">
            <div className="text-xs">Buy Fee: 0.3%</div>
            <div className=" text-secondary text-xs">Sell Fee: 0.3%</div>
            <div className=" text-secondary text-xs">Transfer Fee: 2%</div>
          </div>
        </div>
      </Card>

      <Card variant="secondary" className="my-4">
        <div className="relative">
          <div className="px-4 py-5">
            <div className="row-start-1 pt-2 text-md mb-2 flex items-center justify-center">
              <img
                className="mr-2"
                src={
                  "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png"
                }
                alt="swap Icon"
                width={25}
              />
              BSC
            </div>
          </div>
          <div className="flex md:pb-1 justify-center">
            <Button
              variant={"secondary"}
              prefix={
                <img
                  className="cursor-pointer"
                  src={
                    "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454"
                  }
                  alt="frm icon"
                  width={25}
                />
              }
              title="BUY FRM"
              className="cursor-pointer !text-primary text-sm w-44"
              onClick={() => {
                window.open(
                  "https://swap.multiswap.network/swap?fromChainId=56&fromToken=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&sourceSlippage=2&toChainId=56&toToken=0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc&destinationSlippage=2",
                  "_blank"
                );
              }}
              postfix={
                <ReactIcons.FaExternalLinkAlt
                  className="ml-1 cursor-pointer"
                  size={10}
                />
              }
            />
          </div>
          <div className="grid grid-flow-row justify-center gap-2 pt-3 pb-3 pl-16 pr-16 text-center sm:flex sm:justify-around sm:pt-5 sm:pb-5 sm:pl-16 sm:pr-16">
            <div className=" text-secondary text-xs">Buy Fee: 0.3%</div>
            <div className=" cursor-pointer text-secondary text-xs">
              Sell Fee: 0.3%
            </div>
            <div className=" text-secondary text-xs">Transfer Fee: 0.3%</div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default BuyCard;
