import { atom } from "recoil";

export interface NetworkAndToken {
    networkName: string;
    crucibleName: string;
    networkId : number;
    networkImageUrl : string;
    networkRpcUrl : string;
    tokenName : string;
    tokenAddress : string;
    tokenImageUrl : string;
    stakingAddress: string;
    
}

export interface UnWrapState {
    isUnWraping:boolean;
    errorMessage: string;
    successMessage: string;
    sourceNetworkAndToken?: NetworkAndToken;
    feeOnUnWrap: string;
    destinationNetworkAndToken?: NetworkAndToken;
    amount:string;
    finalUnWrapAmount:string;
}

export const INITIAL_UN_WRAP_STATE:UnWrapState  = {
    isUnWraping:false,
    errorMessage:"",
    successMessage: "",
    feeOnUnWrap:"0.0",
    finalUnWrapAmount: "0.0",
    sourceNetworkAndToken:undefined,
    destinationNetworkAndToken:undefined,
    amount:""
}
export const unWrapingState = atom({
  key: "unWrapingState",
  default: INITIAL_UN_WRAP_STATE,
});