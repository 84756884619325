import { atom, selector } from "recoil";
import { sessionStorageEffect } from "./common";

export const connectedNetworkState = atom({
  key: "connectedNetworkState",
  default: {} as any,
  effects: [sessionStorageEffect("connectedNetwork")],
});

export const destinationAddressState = atom({
  key: "destinationAddressState",
  default: "",
});

export const amountState = atom({
  key: "amountState",
  default: "",
});
export const destinationAmountState = atom({
  key: "destinationAmountState",
  default: "",
});

export const allNetworksListState = atom({
  key: "allNetworksListState",
  default: [],
  effects: [sessionStorageEffect("allNetworks")],
});

export const allTokensListState = atom({
  key: "allTokensListState",
  default: [],
  effects: [sessionStorageEffect("allTokens")],
});

export const sourceNetworkState = atom({
  key: "sourceNetworkState",
  default: {} as any,
});

export const destinationNetworkState = atom({
  key: "destinationNetworkState",
  default: {} as any,
});

export const sourceTokenState = atom({
  key: "sourceTokenState",
  default: {} as any,
});

export const destinationTokenState = atom({
  key: "destinationTokenState",
  default: {} as any,
});

export const estimatedSwapTimeState = atom({
  key: "estimatedSwapTimeState",
  default: {} as any,
});

export const tokenQuotationInfoState = atom({
  key: "tokenQuotationInfoState",
  default: {
    sourceTokenQuotationInfo: {},
    destinationTokenQuotationInfo: {},
    fakeDestinationTokenQuotationInfo: {},
    feeDistribution: {},
  } as any,
});

export const fetchingQuotationState = atom({
  key: "fetchingQuotationState",
  default: false as boolean,
});

export const fetchingGasFeeEstimationState = atom({
  key: "fetchingGasFeeEstimationState",
  default: false as boolean,
});

export const gasFeeEstimationState = atom({
  key: "gasFeeEstimationState",
  default: {} as any,
});

export const isSwappingState = atom({
  key: "isSwappingState",
  default: false as boolean,
});

export const swapAndWithdrawTxStatusState = atom({
  key: "swapAndWithdrawTxStatusState",
  default: "" as string,
});

export const swapTxHashState = atom({
  key: "swapTxHashState",
  default: "" as string,
});

export const withdrawTxHashState = atom({
  key: "withdrawTxHashState",
  default: "" as string,
});

export const swapAndWithdrawTxState = atom({
  key: "swapAndWithdrawTxState",
  default: {} as any,
});

export const isApprovingState = atom({
  key: "isApprovingState",
  default: false as boolean,
});

export const allowanceState = atom({
  key: "allowanceState",
  default: "" as any,
});

export const approveTxHashState = atom({
  key: "approveTxHashState",
  default: "" as string,
});

export const ErrorMessagesState = atom({
  key: "ErrorMessagesState",
  default: "" as string,
});

export const SuccessMessagesState = atom({
  key: "SuccessMessagesState",
  default: "" as string,
});

export const isCCTPState = atom({
  key: "isCCTPState",
  default: false as boolean,
});

export const URLState = selector({
  key: "URLState",
  get: ({ get }) => {
    const fromChainId = get(sourceNetworkState).chainId;
    const toChainId = get(destinationNetworkState).chainId;
    const fromToken = get(sourceTokenState).tokenContractAddress;
    const toToken = get(destinationTokenState).tokenContractAddress;
    const destinationAddress = get(destinationAddressState);
    const amount = get(amountState);
    return {
      fromChainId,
      toChainId,
      fromToken,
      toToken,
      to: destinationAddress,
      amount,
    };
  },
});
