import React, { useEffect, useState } from "react";
import { Card, Button } from "../../components/ui";
import { Icons, Images } from "../../assets";

import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ConnectWalletDialog from "../../components/ConnectWalletDialog";
import BuyCard from "./BuyCard";
import MintCard from "./mint/MintCard";
import Stake from "./stake/Stake";
import UnStake from "./un-stake/UnStake";
import UnWrap from "./un-wrap/UnWrap";
import WithdrawRewards from "./withdraw-rewards/WithdrawRewards";
import SideMenu from "../../layouts/SideMenu";

const CrucibleCard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showWalletConenctDialog, setShowWalletConenctDialog] = useState(false);

  useEffect(() => {
    if (pathname) {
      if (pathname === "/") {
        navigate("/mint");
      }
    }
  }, [pathname]);

  return (
    <>
      <Card variant="primary" className="shadow-2xl !p-4 mt-5">
        <header>
          <div className="md:hidden flex justify-between items-center">
            <p className="text-primaryLight">Crucible</p>
            <SideMenu />
          </div>
          <div className="hidden md:flex md:flex-col justify-between ">
          <span className="text-secondary ml-2">Crucible</span>
          <div className="hidden md:flex justify-between md:px-2 md:space-x-2 mt-2">
            <Button
              variant={`${pathname === "/buy" ? "primary" : "secondary"}`}
              prefix={pathname === "/buy" ? <Icons.BuyIconDark className="w-5 h-5"/> : <Icons.BuyIconLight className="w-5 h-5"/>}
              title="Buy"
              className="bg-background font-normal !text-xs pl-1"
              prefixClassName="!mr-0"
              onClick={() => {
                navigate("/buy");
              }}
            />
            <Button
              variant={`${pathname === "/mint" ? "primary" : "secondary"}`}
              prefix={pathname === "/mint" ? <Icons.MintIconDark className="w-5 h-5"/> : <Icons.MintIconLight className="w-5 h-5"/>}
              title="Mint"
              className="bg-background font-normal !text-xs"
              prefixClassName="!mr-0"
              onClick={() => {
                navigate("/mint");
              }}
            />
             <Button
              variant={`${pathname === "/stake" ? "primary" : "secondary"}`}
              prefix={pathname === "/stake" ? <Icons.StakeIconDark className="w-5 h-5"/> : <Icons.StakeIconLight className="w-5 h-5"/>}
              title="Stake"
              className="bg-background font-normal !text-xs"
              prefixClassName="!mr-0"
              onClick={() => {
                navigate("/stake");
              }}
            />
             <Button
              variant={`${pathname === "/withdraw-rewards" ? "primary" : "secondary"}`}
              prefix={pathname === "/withdraw-rewards" ? <Icons.RewardsIconDark className="w-5 h-5"/> : <Icons.RewardsIconLight className="w-5 h-5"/>}
              title="Rewards"
              className="bg-background font-normal !text-xs"
              prefixClassName="!mr-0"
              onClick={() => {
                navigate("/withdraw-rewards");
              }}
            />
            <Button
              variant={`${pathname === "/un-stake" ? "primary" : "secondary"}`}
              prefix={pathname === "/un-stake" ? <Icons.UnstakeIconDark className="w-5 h-5"/> : <Icons.UnstakeIconLight className="w-5 h-5"/>}
              title="UnStake"
              className="bg-background font-normal !text-xs"
              prefixClassName="!mr-0"
              onClick={() => {
                navigate("/un-stake");
              }}
            />
             <Button
              variant={`${pathname === "/un-wrap" ? "primary" : "secondary"}`}
              prefix={pathname === "/un-wrap" ? <Icons.UnwrapIconDark className="w-5 h-5"/> : <Icons.UnwrapIconLight className="w-5 h-5"/>}
              title="UnWrap"
              className="bg-background font-normal !text-xs"
              prefixClassName="!mr-0"
              onClick={() => {
                navigate("/un-wrap");
              }}
            />
          </div>
          </div>
          
        </header>

        <article className="relative">
          <Routes>
            <Route index element={<BuyCard />} />
            <Route path="/buy" element={<BuyCard />} />
            <Route path="/mint" element={<MintCard />} />
            <Route path="/stake" element={<Stake />} />
            <Route path="/un-stake" element={<UnStake />} />
            <Route path="/un-wrap" element={<UnWrap />} />
            <Route path="/withdraw-rewards" element={<WithdrawRewards />} />
            <Route path="*" element={<Navigate to={"/mint"}/>} />
          </Routes>
        </article>
      </Card>
      <img src={Images.FerrumBanner} alt="ferrum banner" className="" />

      <ConnectWalletDialog
        show={showWalletConenctDialog}
        onHide={() => setShowWalletConenctDialog(false)}
      />
    </>
  );
};

export default CrucibleCard;
