import { NetworkAndToken } from "../recoil/mint";

export const DEFAULT_TOKEN_LOGO =
  "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/Token-import.png";

export const SwapAndWithdrawTxStatuses: { [key: string]: string } = {
  generatorSignatureCreated: "Generator Signature Created",
  generatorSignatureFailed: "Swap failed!",
  validatorSignatureCreated: "Validator Signature Created",
  validatorSignatureFailed: "Swap failed!",
  masterValidationFailed: "Swap failed!",
  swapPending: "Tx Processing - Please Wait",
  swapCreated: "Swap Created",
  swapCompleted: "Tx Processing - Please Wait",
  swapFailed: "Swap failed! Please try again.",
  swapWithdrawGenerated: "Swap Withdraw Generated",
  swapWithdrawPending: "Tx Processing - Please Wait",
  swapWithdrawFailed: "Swap Withdraw Failed",
  swapWithdrawCompleted: "Swap Withdraw Completed",
};

export const SwapAndWithdrawErrorMessages: { [key: string]: string } = {
  generatorSignatureFailed: "Generator Signature Failed",
  validatorSignatureFailed: "Validator Signature Failed",
  masterValidationFailed: "Master Validation Failed",
  swapFailed: "Swap Failed",
  swapWithdrawFailed: "Swap Withdraw Failed",
};

export const FailedStatuses = [
  "generatorSignatureFailed",
  "validatorSignatureFailed",
  "masterValidationFailed",
  "swapFailed",
  "swapWithdrawFailed",
];

export const getTwitterPostContent = (
  sourceTokenSymbol: string,
  sourceNetworkName: string,
  destinationTokenSymbol: string,
  destinationNetworkName: string
) => {
  return `https://twitter.com/intent/post?text=%F0%9F%9A%80%20Just%20used%20%400xMultiSwap%20by%20%40FerrumNetwork%0A%20to%20swap%20%24${sourceTokenSymbol}%20on%20%23${sourceNetworkName}%20for%20%24${destinationTokenSymbol}%20on%20%23${destinationNetworkName}%20with%20a%20click%20on%20a%20button.%0A%0ABlazing%20fast%20swaps%20and%20super%20easy%20to%20use%21%20%F0%9F%94%A5%0A%0A%F0%9F%91%89Try%20it%20yourself%3A%0Ahttps%3A%2F%2Fswap.multiswap.network%0A%0A%24FRM%20%24SWAPS`;
};

export const getDocsURL = () =>
  "https://docs.ferrumnetwork.io/ferrum-network-ecosystem/v/multiswap-and-multichain-liquidity-pool-bridge/";

export const ProviderAssetsLogo: { [key: string]: string } = {
  Foundary: "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454",
  "1Inch": "https://etherscan.io/token/images/1inch_32.png?=v454",
};

export const getDiscordSupportLink = () =>
  "https://discord.com/invite/HEfKq57asd";

export const TABLE_HEADER = [
  "FROM",
  "TO",
  "DATE UTC",
  "FROM HASH",
  "TO HASH",
  "PROVIDER",
  "STATUS",
  "DETAILS",
];

export const TX_TABLE_HEADER = [
  "FROM",
  "TO",
  "DATE UTC",
  "FROM HASH",
  "TO HASH",
  "COMPLETED TIME",
  "PROVIDER",
  "STATUS",
];

export const ARBITRUM_FRM: NetworkAndToken = {
  networkName: "Arbitrum",
  crucibleName: "ETHEREUM_ARBITRUM",
  networkId: 42161,
  networkImageUrl:
    "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png",
  networkRpcUrl:
    "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
  tokenName: "FRM",
  tokenAddress: "0x9f6abbf0ba6b5bfa27f4deb6597cc6ec20573fda",
  tokenImageUrl: "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454",
  stakingAddress: "0xB4927895cbEE88E651e0582893051b3B0f8D7DB8",
  router: "0xD2ad2e820Df8b5F55074b73fb1Fb821d8eCaDEfb",
  factory: "0xd138ba4c9A04eBE9CEDF23161a76057fA800B017 ",
};

export const ARBITRUM_CFRM: NetworkAndToken = {
  networkName: "Arbitrum",
  crucibleName: "ETHEREUM_ARBITRUM",
  networkId: 42161,
  networkImageUrl:
    "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/arbitrum-logo.png",
  networkRpcUrl:
    "https://nd-499-825-018.p2pify.com/5d8bab30e1462f48144c36f18d2ee958",
  tokenName: "cFRM",
  tokenAddress: "0xe685d3CC0be48BD59082eDe30C3b64CbFc0326e2",
  tokenImageUrl:
    "https://ferrum.network/nwp/assets/Ferrum-Network/icon-network-cfrm.svg",
  stakingAddress: "0xB4927895cbEE88E651e0582893051b3B0f8D7DB8",
  router: "0xD2ad2e820Df8b5F55074b73fb1Fb821d8eCaDEfb",
  factory: "0xd138ba4c9A04eBE9CEDF23161a76057fA800B017 ",
};

export const BSC_FRM: NetworkAndToken = {
  networkName: "BSC",
  crucibleName: "BSC",
  networkId: 56,
  networkImageUrl:
    "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png",
  networkRpcUrl: "https://bsc-dataseed.binance.org/",
  tokenName: "FRM",
  tokenAddress: "0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc",
  tokenImageUrl: "https://etherscan.io/token/images/ferrumnetwork_32.png?=v454",
  stakingAddress: "0x9f6AbbF0Ba6B5bfa27f4deb6597CC6Ec20573FDA",
  factory: "0xaa703e8114600C83240145B4B524d7547A3743be",
  router: "0x476F21e9c9787A76cdD7f25dd6Db4039c0ad3176",
};

export const BSC_CFRM: NetworkAndToken = {
  networkName: "BSC",
  crucibleName: "BSC",
  networkId: 56,
  networkImageUrl:
    "https://gateway-revamp.s3.us-east-2.amazonaws.com/multiswap/ic_bsc.png",
  networkRpcUrl: "https://bsc-dataseed.binance.org/",
  tokenName: "cFRM",
  tokenAddress: "0xaf329a957653675613D0D98f49fc93326AeB36Fc",
  tokenImageUrl:
    "https://ferrum.network/nwp/assets/Ferrum-Network/icon-network-cfrm.svg",
  stakingAddress: "0x35E15ff9eBB37D8C7A413fD85BaD515396DC8008",
  factory: "0xaa703e8114600C83240145B4B524d7547A3743be",
  router: "0x476F21e9c9787A76cdD7f25dd6Db4039c0ad3176",
};

export const FRM_CONTRACT_ADDRESS: { [key: string]: string } = {
  "56": "0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc",
  "42161": "0x9f6abbf0ba6b5bfa27f4deb6597cc6ec20573fda",
};

export const C_FRM_CONTRACT_ADDRESS: { [key: string]: string } = {
  "56": "0xaf329a957653675613D0D98f49fc93326AeB36Fc",
  "42161": "0xe685d3CC0be48BD59082eDe30C3b64CbFc0326e2",
};
