import clsx from "clsx";
import React, { JSX } from "react";

type Variant = "primary" | "secondary" | "info";

type Size = "sm" | "md" | "lg" | "xl";

export const Card = ({
  variant,
  className,
  children,
  key,
  size = "md",
}: {
  variant: Variant;
  className?: string;
  children: JSX.Element[] | JSX.Element;
  size?: Size;
  key?: string | number;
}) => {
  const classes = clsx(
    {
      "max-w-[1200px] sm:min-w-[1200px]":
        size === "xl" && variant === "primary",
      "max-w-[900px] sm:min-w-[900px]": size === "lg" && variant === "primary",
      "max-w-[580px] sm:min-w-[580px]": size === "md" && variant === "primary",
      "sm:min-w-[300px]": size === "sm" && variant === "primary",
    },
    {
      "w-full rounded-md bg-background md:p-3 sm:p-0": variant === "primary",

      "w-full max-w-[580px] rounded-md bg-[#11131580] text-white":
        variant === "secondary",
      "rounded-md bg-background p-6": variant === "info",
    },
    className,
  );

  return (
    <div className={classes} key={key}>
      {children}
    </div>
  );
};
