import { useEffect, useState } from "react";
import { Button, Variant } from "../../../components/ui";
import { Gif, ReactIcons } from "../../../assets";

import ConnectWalletDialog from "../../../components/ConnectWalletDialog";
// import { Tooltip } from "react-tooltip";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  useWalletConnector,
  useFoundrySendTransaction,
  useSwitchNetwork,
} from "foundry";
import { useTokenBalance } from "../../../hooks";
import {
  WithdrawRewardsState,
  withdrawingState,
} from "../../../recoil/withdrawRewards";
import { CrucibleClient } from "../../../utils/contractSync/CrucibleClient";
import WithdrawRewardsDetailsSelectorCard from "./WithdrawRewardsDetailsSelectorCard";
import { ARBITRUM_CFRM, BSC_CFRM } from "../../../utils/constants";
import { mintingState } from "../../../recoil/mint";
import { MessageState, messagingState } from "../../../recoil/app";

const WithdrawRewardsCard = () => {
  const [withdrawRewardsState, setWithdrawRewardsState] =
    useRecoilState<WithdrawRewardsState>(withdrawingState);
  const [messageState, setMessageState] =
    useRecoilState<MessageState>(messagingState);

  const { rewardOf, finalReward, feeOnWithdraw } = withdrawRewardsState;

  const {
    isWithdrawing,
    sourceNetworkAndToken,
    destinationNetworkAndToken,
    amount,
  } = withdrawRewardsState;
  const [showConnectWalletDialog, setShowConnectWalletDialog] =
    useState<boolean>(false);
  const { isConnected, walletAddress, currentNetworkChainId } =
    useWalletConnector();
  const { switchWeb3Network } = useSwitchNetwork();

  const { hash, status, error, reset, sendWeb3Transaction } =
    useFoundrySendTransaction();

  const getCrucibleInfoAndUserInfo = async () => {
    if (destinationNetworkAndToken && sourceNetworkAndToken && walletAddress) {
      const client = new CrucibleClient();
      const info = await client.getCrucible(
        `${destinationNetworkAndToken.crucibleName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        destinationNetworkAndToken.stakingAddress
      );
      const userInfo = await client.getUserCrucibleInfo(
        `${destinationNetworkAndToken.crucibleName?.toUpperCase()}:${destinationNetworkAndToken?.tokenAddress}`,
        destinationNetworkAndToken.stakingAddress,
        walletAddress
      );
      console.log("userInfo", userInfo);
      console.log("crucibleInfo", info);
      console.log("feed on transfer", info?.data.feeOnTransferRate);
      console.log("reward ", userInfo?.data.stakes[0].rewardOf);
      setWithdrawRewardsState({
        ...withdrawRewardsState,
        rewardOf: userInfo?.data.stakes[0].rewardOf,
        isWithdrawing: false,
        finalReward: Number(userInfo?.data.stakes[0].rewardOf).toString()
      });
    }
  };

  useEffect(() => {
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });
  }, []);

  useEffect(() => {
    if (
      destinationNetworkAndToken?.tokenAddress &&
      walletAddress &&
      sourceNetworkAndToken?.tokenAddress
    ) {
      getCrucibleInfoAndUserInfo();
    }
  }, [destinationNetworkAndToken, walletAddress, sourceNetworkAndToken]);

  useEffect(() => {
    if (currentNetworkChainId === ARBITRUM_CFRM.networkId) {
      setWithdrawRewardsState({
        ...withdrawRewardsState,
        sourceNetworkAndToken: ARBITRUM_CFRM,
        destinationNetworkAndToken: ARBITRUM_CFRM,
      });
    } else if (currentNetworkChainId === BSC_CFRM.networkId) {
      setWithdrawRewardsState({
        ...withdrawRewardsState,
        sourceNetworkAndToken: BSC_CFRM,
        destinationNetworkAndToken: BSC_CFRM,
      });
    }
    if (isConnected) {
      setShowConnectWalletDialog(false);
    }
  }, [isConnected, currentNetworkChainId]);

  useEffect(() => {
    if (isWithdrawing && hash && status === "success") {
      setMessageState({
        successMessage: "Withdraw successful",
        errorMessage: "",
      });
      getCrucibleInfoAndUserInfo();
      reset();
    } else if (isWithdrawing && error) {
      setWithdrawRewardsState({
        ...withdrawRewardsState,
        isWithdrawing: false,
        amount: rewardOf,
      });
      setMessageState({
        errorMessage: "An error occured while withdrawing",
        successMessage: "",
      });
      reset();
    }
  }, [isWithdrawing, hash, status, error]);

  const handlewithdrawRewardsAction = async () => {
    setWithdrawRewardsState({
      ...withdrawRewardsState,
      isWithdrawing: true,
    });
    setMessageState({
      errorMessage: "",
      successMessage: "",
    });
    // un wrap logic using sourceNetworkAndToken

    if (sourceNetworkAndToken) {
      const client = new CrucibleClient();
      const tx = await client.withdrawRewards(
        `${sourceNetworkAndToken.networkName?.toUpperCase()}:${sourceNetworkAndToken?.tokenAddress}`,
        walletAddress,
        finalReward,
        sourceNetworkAndToken.stakingAddress
      );
      if (tx) {
        const payload = {
          from: tx.from,
          to: tx.contract,
          data: tx.data,
        };
        sendWeb3Transaction(payload);
      }
    }
  };

  const getButtonVariant = (): Variant => {
    return isWithdrawing ||
      (isConnected &&
        sourceNetworkAndToken?.networkId === currentNetworkChainId &&
        (Number.isNaN(rewardOf) || Number(rewardOf) <= 0))
      ? "tertiary"
      : "primary";
  };

  const isButtonDisabled = () => {
    return isWithdrawing;
  };

  const getButtonPrefix = () => {
    if (isWithdrawing ) {
      return <img src={Gif.Loader} className="h-4 w-4" />;
    }
  };

  const getButtonTitle = () => {

    if (!isConnected) {
      return "Connect Wallet";
    }

    if (isConnected && !sourceNetworkAndToken) {
      return "Select Network";
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      return "Switch Network";
    }

    if (isWithdrawing) {
      return "Withdrawing";
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(finalReward) || Number(finalReward) <= 0)
    ) {
      return "Not Engough Amount To Withdraw";
    }

    return "Withdraw";
  };

  const handleButtonClick = () => {
    if (!isConnected) {
      setShowConnectWalletDialog(true);
      return;
    }

    if (isConnected && !sourceNetworkAndToken) {
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken &&
      sourceNetworkAndToken.networkId !== currentNetworkChainId
    ) {
      switchWeb3Network(sourceNetworkAndToken.networkId.toString());
      return;
    }

    if (
      isConnected &&
      sourceNetworkAndToken?.networkId === currentNetworkChainId &&
      (Number.isNaN(finalReward) || Number(finalReward) <= 0)
    ) {
      return;
    }
    handlewithdrawRewardsAction();
  };

  return (
    <>
      <WithdrawRewardsDetailsSelectorCard title="You’ll Receive" side="source" />
      
      <Button
        variant={`${getButtonVariant()}`}
        title={getButtonTitle()}
        className="mt-4 w-full text-xl"
        prefix={getButtonPrefix()}
        // postfix={getButtonPostfix()}
        disabled={isButtonDisabled()}
        onClick={handleButtonClick}
      />

      <ConnectWalletDialog
        show={showConnectWalletDialog}
        onHide={() => setShowConnectWalletDialog(false)}
      />
    </>
  );
};

export default WithdrawRewardsCard;
