import { atom } from "recoil";

export interface NetworkAndToken {
    networkName: string;
    crucibleName: string;
    networkId : number;
    networkImageUrl : string;
    networkRpcUrl : string;
    tokenName : string;
    tokenAddress : string;
    tokenImageUrl : string;
    stakingAddress: string;
    
}

export interface WithdrawRewardsState {
    isWithdrawing: boolean;
    errorMessage: string;
    successMessage: string;
    sourceNetworkAndToken?: NetworkAndToken;
    destinationNetworkAndToken?: NetworkAndToken;
    amount:string;
    rewardOf:string;
    feeOnWithdraw:string;
    finalReward:string;
}

export const INITIAL_UN_WRAP_STATE: WithdrawRewardsState  = {
    isWithdrawing:false,
    errorMessage:"",
    successMessage: "",
    sourceNetworkAndToken:undefined,
    destinationNetworkAndToken:undefined,
    feeOnWithdraw: "0.0",
    amount:"",
    rewardOf:"0.0",
    finalReward: "0.0",
}
export const withdrawingState = atom({
  key: "withdrawRewardsState",
  default: INITIAL_UN_WRAP_STATE,
});