import { useEffect } from "react";
import { AxiosError } from "axios";
import * as authServer from "../api/AuthCrud";
import { useRecoilState } from "recoil";
import { authTokenState } from "../recoil/auth";

const useAuth = () => {
  const [authToken, setAuthToken] = useRecoilState(authTokenState);

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000 * 60 * 20); // Refresh the token every 20 minutes
    console.log("authToken", "Refresh the token every 20 minutes");
    if (authToken) {
      refreshAuthToken(authToken);
    }
    return () => clearInterval(intervalId);
  }, []);

  const setToken = (token: string) => {
    localStorage.setItem("authToken", JSON.stringify(token));
    setAuthToken(token);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setAuthToken("");
  };

  const refreshAuthToken = async (oldToken: string) => {
    try {
      const response = await authServer.refreshToken(oldToken);
      if (response) {
        const newAccessToken = response.data.body.token;
        setAuthToken(newAccessToken);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      if (error instanceof AxiosError) {
        console.log(error?.response?.data?.status?.message);
      } else if (error instanceof Error) {
        console.log(error?.message);
      }
    }
  };

  return { authToken, setToken, logout };
};

export default useAuth;
