import { atom } from "recoil";

export interface NetworkAndToken {
  networkName: string;
  crucibleName: string;
  networkId: number;
  networkImageUrl: string;
  networkRpcUrl: string;
  tokenName: string;
  tokenAddress: string;
  tokenImageUrl: string;
  stakingAddress: string;
  router: string;
  factory: string;
}

export interface MintState {
  allowance: string;
  isApproving: boolean;
  isMinting: boolean;
  errorMessage: string;
  successMessage: string;
  sourceNetworkAndToken?: NetworkAndToken;
  destinationNetworkAndToken?: NetworkAndToken;
  amount: string;
  rewardOf: "";
}

export const INITIAL_MINTING_STATE: MintState = {
  allowance: "",
  isApproving: false,
  isMinting: false,
  errorMessage: "",
  successMessage: "",
  sourceNetworkAndToken: undefined,
  destinationNetworkAndToken: undefined,
  amount: "",
  rewardOf: "",
};
export const mintingState = atom({
  key: "mintingState",
  default: INITIAL_MINTING_STATE,
});
