import { atom } from "recoil";

export interface MessageState {
  errorMessage: string;
  successMessage: string;
}

const INITIAL_MESSAGE_STATE:MessageState = {
    errorMessage:"",
    successMessage:"",
}
export const messagingState = atom({
  key: "messagingState",
  default: INITIAL_MESSAGE_STATE,
});
