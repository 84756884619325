import React, { useState, useEffect } from "react";
import { Button, Card, InputField } from "../../../components/ui";
import { Gif, Icons, ReactIcons } from "../../../assets";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useTokenBalance } from "../../../hooks";
import { IsObjectEmpty } from "../../../utils/helper";
import { useWalletConnector } from "foundry";
import { unWrapingState, UnWrapState } from "../../../recoil/unWrap";
import UnWrapNetworkSelectDialog from "./UnWrapNetworkSelectDialog";

type SwapSide = "source" | "destination";

const UnWrapDetailsSelectorCard = ({
  title,
  side,
  balanceUpdated,
}: {
  title: string;
  side: SwapSide;
  balanceUpdated: boolean;
}) => {
  const [showNetworkSelectorDialog, setShowNetworkSelectorDialog] =
    useState(false);
  const [unWrapState, setUnWrapState] =
    useRecoilState<UnWrapState>(unWrapingState);

  const {
    isUnWraping,
    sourceNetworkAndToken,
    destinationNetworkAndToken,
    amount,
    feeOnUnWrap,
    finalUnWrapAmount,
  } = unWrapState;

  const { walletAddress, isConnected } = useWalletConnector();

  const { tokenBalance } = useTokenBalance(
    walletAddress,
    balanceUpdated,
    sourceNetworkAndToken?.tokenAddress,
    false,
    sourceNetworkAndToken?.networkRpcUrl
  );

  const handleSourceAmountAction = async (value: string) => {
    setUnWrapState({
      ...unWrapState,
      amount: value,
      finalUnWrapAmount: Number(
        Number(value) -
          Number(value) * (Number(feeOnUnWrap ? feeOnUnWrap : "0") / 100)
      ).toString(),
    });
  };

  return (
    <>
      <Card variant="secondary" className="my-4 h-36 sm:h-28">
        <div className="relative">
          <div className="grid grid-cols-4 grid-rows-[0.9fr_1fr] items-center px-4 py-5 sm:py-0 sm:pt-0">
            <div className="row-start-1 pt-2 text-sm">{title}</div>
            <div className="col-span-3 row-start-1 sm:col-span-2 sm:row-start-2">
              <button
                className={`flex w-full tertiary items-center justify-between rounded-xl border border-backgroundLight bg-backgroundDark p-2 ${
                  side === "destination" ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (side !== "destination") {
                    setShowNetworkSelectorDialog(true);
                  }
                }}
                disabled={isUnWraping}
              >
                {(!IsObjectEmpty(sourceNetworkAndToken) && side === "source") ||
                (!IsObjectEmpty(destinationNetworkAndToken) &&
                  side === "destination") ? (
                  <div className="mx-auto flex items-center">
                    <div className="flex items-center">
                      <img
                        src={
                          side === "source"
                            ? sourceNetworkAndToken?.networkImageUrl
                            : destinationNetworkAndToken?.networkImageUrl
                        }
                        alt="network logo"
                        className="mr-2"
                        width={25}
                      />
                      <span className="w-full whitespace-nowrap text-sm">
                        {side === "source"
                          ? sourceNetworkAndToken?.networkName
                          : destinationNetworkAndToken?.networkName}
                      </span>
                      {!IsObjectEmpty(sourceNetworkAndToken) &&
                      side !== "destination" ? (
                        <ReactIcons.RiArrowDropDownLine size={30} />
                      ) : undefined}
                    </div>
                    <img
                      src={Icons.PathIcon}
                      alt="path icon"
                      className="mx-2 sm:mx-4"
                    />
                    <div
                      className={`flex items-center  ${!IsObjectEmpty(sourceNetworkAndToken) && side !== "destination" ? "opacity-50" : ""}`}
                    >
                      <img
                        src={
                          side === "source"
                            ? sourceNetworkAndToken?.tokenImageUrl
                            : destinationNetworkAndToken?.tokenImageUrl
                        }
                        alt="token logo"
                        className="mr-2"
                        width={25}
                      />
                      <span className="w-full whitespace-nowrap text-sm">
                        {side === "source"
                          ? sourceNetworkAndToken?.tokenName
                          : destinationNetworkAndToken?.tokenName}
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className="w-full text-[12px] sm:text-sm">
                    Select Network
                  </span>
                )}
                {IsObjectEmpty(sourceNetworkAndToken) ? (
                  <ReactIcons.RiArrowDropDownLine size={30} />
                ) : undefined}
              </button>
            </div>
            <InputField
              disabled={
                side === "destination" || (side === "source" && isUnWraping)
              }
              type="integer"
              placeholder="0.0"
              value={side === "destination" ? finalUnWrapAmount : amount}
              className="col-span-4 row-start-2 text-4xl sm:col-span-2 sm:row-end-2 sm:pl-2"
              inputFieldClassName="text-left w-full sm:text-right"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSourceAmountAction(e.target.value)
              }
            />
          </div>
          {!IsObjectEmpty(sourceNetworkAndToken) &&
            !IsObjectEmpty(destinationNetworkAndToken) &&
            side === "source" &&
            isConnected &&
            tokenBalance && (
              <div className="absolute bottom-[2px] right-[15px] flex w-full items-center justify-end text-xs sm:right-4 sm:top-[95px]">
                <p className="mr-2">
                  <span className="text-backgroundLight">Balance:</span>{" "}
                  <span className="">{Number(tokenBalance).toFixed(6)}</span>
                </p>{" "}
                <Button
                  disabled={isUnWraping}
                  variant="tertiary"
                  title="MAX"
                  className=""
                  style={{ fontSize: "10px", padding: "0.1rem 0.5rem" }}
                  onClick={() => handleSourceAmountAction(tokenBalance)}
                />
              </div>
            )}
        </div>
      </Card>
      {showNetworkSelectorDialog ? (
        <UnWrapNetworkSelectDialog
          show={showNetworkSelectorDialog}
          onHide={() => setShowNetworkSelectorDialog(false)}
          direction={side}
        />
      ) : null}
    </>
  );
};

export default UnWrapDetailsSelectorCard;
