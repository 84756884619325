import CryptoJS from "crypto-js";
import moment from "moment";
import { Big } from 'big.js';

export const truncateMiddle = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }

  const halfLength = Math.floor((maxLength - 3) / 2);
  const firstHalf = str.slice(0, halfLength);
  const secondHalf = str.slice(str.length - halfLength);

  return `${firstHalf}...${secondHalf}`;
};

export const getEncryptedKey = (data = "") => {
  let jwtSecret = process.env.REACT_APP_JWT_SECRET || "";
  let key = process.env.REACT_APP_API_KEY_FOR_APP_USER || "";
  if (data) {
    key = data;
  }
  let encryptedKey = CryptoJS.AES.encrypt(key, jwtSecret).toString();

  return encryptedKey;
};

export function getTokenForFiber() {
  let timelapse = 5;
  let currentTime = new Date();
  let startDateTime = moment(currentTime)
    .subtract("minutes", timelapse)
    .utc()
    .format();
  let endDateTime = moment(currentTime)
    .add("minutes", timelapse)
    .utc()
    .format();
  const randomBytes = new Uint8Array(512);
  let randomKey: Uint8Array | string =
    window.crypto.getRandomValues(randomBytes);
  randomKey = randomKey.toString();
  let apiKey = process.env.REACT_APP_API_KEY_FOR_GATEWAY || "";
  let tokenBody: { [key: string]: string } = {};
  tokenBody.startDateTime = startDateTime;
  tokenBody.endDateTime = endDateTime;
  tokenBody.randomKey = randomKey;
  tokenBody.apiKey = apiKey;
  let strTokenBody = JSON.stringify(tokenBody);
  let encryptedSessionToken = getEncryptedKey(strTokenBody);
  return encryptedSessionToken;
}
export const IsObjectEmpty = (obj: Object|undefined) => {
  return !obj || (obj !== undefined && Object.keys(obj).length === 0);
};

export const toUTCDateFormat = (dateString: string | number | Date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const formattedTime = date.toISOString().split("T")[1].split(".")[0];

  return `${day}-${months[monthIndex]}-${year} ${formattedTime}`;
};

export const copyTextToClipboard = async (tx: string) => {
  navigator.clipboard.writeText(tx);
};

export class ParseBigError extends Error { }

export class BigUtils {
	static truthy(b?: Big): boolean {
		return !!b && !(new Big(0).eq(b));
	}

	static safeParse(s: string): Big {
		try {
			return new Big(s);
		} catch (e) {
			return new Big('0');
		}
	}

	static parseOrThrow(s: string, varName: string): Big {
		try {
			return new Big(s);
		} catch (e) {
			throw new ParseBigError(`Error parsing ${varName}. "${s}" is not a valid number`);
		}
	}
}

export function toPlainString(num: string) {
  return (''+ +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function(a,b,c,d,e) {
      return e < 0
        ? b + '0.' + Array(1-e-c.length).join('0') + c + d
        : b + c + d + Array(e-d.length+1).join('0');
    });
}