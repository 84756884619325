import React, { useEffect, useState } from "react";
import { Button, Card, InputField } from "../../../components/ui";
import { Icons, ReactIcons } from "../../../assets";
import { useRecoilState, useRecoilValue } from "recoil";

import { useTokenBalance } from "../../../hooks";
import { IsObjectEmpty, toPlainString } from "../../../utils/helper";
import { useWalletConnector } from "foundry";
import {
  withdrawingState,
  WithdrawRewardsState,
} from "../../../recoil/withdrawRewards";
import WithdrawRewardsNetworkSelectDialog from "./WithdrawRewardsNetworkSelectDialog";
import { CrucibleClient } from "../../../utils/contractSync/CrucibleClient";

type SwapSide = "source" | "destination";

const WithdrawRewardsDetailsSelectorCard = ({
  title,
  side,
}: {
  title: string;
  side: SwapSide;
}) => {
  const [showNetworkSelectorDialog, setShowNetworkSelectorDialog] =
    useState(false);

  const [withdrawRewardsState, setWithdrawRewardsState] =
    useRecoilState<WithdrawRewardsState>(withdrawingState);

  const {
    isWithdrawing,
    sourceNetworkAndToken,
    destinationNetworkAndToken,
    rewardOf,
    feeOnWithdraw,
    finalReward,
  } = withdrawRewardsState;

  const reward = toPlainString(finalReward);

  return (
    <>
      <Card variant="secondary" className="my-4 h-36 sm:h-28">
        <div className="relative">
          <div className="grid grid-cols-4 grid-rows-[0.9fr_1fr] items-center px-4 py-5 sm:py-0 sm:pt-0">
            <div className="row-start-1 pt-2 text-sm">{title}</div>
            <div className="col-span-3 row-start-1 sm:col-span-2 sm:row-start-2">
              <button
                className={`flex w-full tertiary items-center justify-between rounded-xl border border-backgroundLight bg-backgroundDark p-2 ${
                  side === "destination" ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (side !== "destination") {
                    setShowNetworkSelectorDialog(true);
                  }
                }}
                disabled={isWithdrawing}
              >
                {(!IsObjectEmpty(sourceNetworkAndToken) && side === "source") ||
                (!IsObjectEmpty(destinationNetworkAndToken) &&
                  side === "destination") ? (
                  <div className="mx-auto flex items-center">
                    <div className="flex items-center">
                      <img
                        src={
                          side === "source"
                            ? sourceNetworkAndToken?.networkImageUrl
                            : destinationNetworkAndToken?.networkImageUrl
                        }
                        alt="network logo"
                        className="mr-2"
                        width={25}
                      />
                      <span className="w-full whitespace-nowrap text-sm">
                        {side === "source"
                          ? sourceNetworkAndToken?.networkName
                          : destinationNetworkAndToken?.networkName}
                      </span>
                      {!IsObjectEmpty(sourceNetworkAndToken) &&
                      side !== "destination" ? (
                        <ReactIcons.RiArrowDropDownLine size={30} />
                      ) : undefined}
                    </div>
                    <img
                      src={Icons.PathIcon}
                      alt="path icon"
                      className="mx-2 sm:mx-4"
                    />
                    <div
                      className={`flex items-center  ${!IsObjectEmpty(sourceNetworkAndToken) && side !== "destination" ? "opacity-50" : ""}`}
                    >
                      <img
                        src={
                          side === "source"
                            ? sourceNetworkAndToken?.tokenImageUrl
                            : destinationNetworkAndToken?.tokenImageUrl
                        }
                        alt="token logo"
                        className="mr-2"
                        width={25}
                      />
                      <span className="w-full whitespace-nowrap text-sm">
                        {side === "source"
                          ? sourceNetworkAndToken?.tokenName
                          : destinationNetworkAndToken?.tokenName}
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className="w-full text-[12px] sm:text-sm">
                    Select Network
                  </span>
                )}
                {IsObjectEmpty(sourceNetworkAndToken) ? (
                  <ReactIcons.RiArrowDropDownLine size={30} />
                ) : undefined}
              </button>
            </div>
            <InputField
              disabled={side === "destination" || side === "source"}
              type="integer"
              placeholder={reward}
              value={reward}
              className="col-span-4 row-start-2 text-4xl sm:col-span-2 sm:row-end-2 sm:pl-2"
              inputFieldClassName="text-left w-full sm:text-right"
            />
          </div>
        </div>
      </Card>
      {showNetworkSelectorDialog ? (
        <WithdrawRewardsNetworkSelectDialog
          show={showNetworkSelectorDialog}
          onHide={() => setShowNetworkSelectorDialog(false)}
          direction={side}
        />
      ) : null}
    </>
  );
};

export default WithdrawRewardsDetailsSelectorCard;
