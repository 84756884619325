import React, { useState, useEffect } from 'react';
import { Icons } from '../assets';
import { useNavigate,useLocation  } from 'react-router-dom';

interface Option {
  value: string;
  label: string;
  icon: JSX.Element;
  iconDark: JSX.Element;
}

const options: Option[] = [
  { value: 'buy', label: 'Buy', icon: <Icons.BuyIconLight className='!h-5 !w-5' /> ,iconDark: <Icons.BuyIconDark className='!h-5 !w-5' /> },
  { value: 'mint', label: 'Mint', icon: <Icons.MintIconLight className='!h-5 !w-5' /> ,iconDark: <Icons.MintIconDark className='!h-5 !w-5' />},
  { value: 'stake', label: 'Stake', icon: <Icons.StakeIconLight className='!h-5 !w-5' /> ,iconDark: <Icons.StakeIconDark className='!h-5 !w-5' />},
  { value: 'withdraw-rewards', label: 'Rewards', icon: <Icons.RewardsIconLight className='!h-5 !w-5' /> ,iconDark: <Icons.RewardsIconDark className='!h-5 !w-5'/>},
  { value: 'un-stake', label: 'Unstake', icon: <Icons.UnstakeIconLight className='!h-5 !w-5' /> ,iconDark: <Icons.UnstakeIconDark className='!h-5 !w-5'/>},
  { value: 'un-wrap', label: 'Unwrap', icon: <Icons.UnwrapIconLight className='!h-5 !w-5' /> ,iconDark: <Icons.UnwrapIconDark className='!h-5 !w-5'/> },
];

const SideMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();


  useEffect(() => {
    const currentOption = options.find(option => `/${option.value}` === pathname);
    setSelectedOption(currentOption || null);
  }, [pathname]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    navigate(`/${option.value}`);
  };

  return (
    <div className="w-48 relative">
      <div
        className={`p-[6px] border text-primaryLight border-primary rounded-lg  cursor-pointer ${selectedOption ? "bg-primary" : "bg-background"}`}
        onClick={handleToggle}
      >
        {selectedOption ? (
          <div className='flex justify-between items-center'>
            <span className={`flex ${selectedOption ? "text-primaryContrast" : ""}` }>
            {selectedOption ? selectedOption.iconDark : ""} {selectedOption.label}
            </span>
            <Icons.DropDownArrowDark />
          </div>
        ) : (
          <span className='flex items-center justify-between'>
            <p className='text-primaryLight'>Select Action</p>
            <img src={Icons.DropDownArrow} alt="dropdown" />
          </span>
        )}
      </div>
      {isOpen && (
        <div className="absolute w-[100%] top-8 border-b border-l border-r rounded-b-lg border-primary z-[1] bg-background">
          {options.map(option => (
            <div
              key={option.value}
              className="p-[10px] flex items-center cursor-pointer text-primaryLight"
              onClick={() => handleSelect(option)}
            >
              {option.icon} {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SideMenu;



